import React from 'react';
import Intercom from 'react-intercom';

const IntercomApp = (props) => {

    return(
      <div className="app">
        <Intercom appID="dqnpwqzs" />
      </div>
    );
  }

export default IntercomApp
